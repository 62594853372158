<template>
  <div>

    <div
      v-for="(row,index) in dataList"
      :key="index"
    >
      <b-card no-body>
        <b-card-header>
          <b-card-title>
            {{ row.title }}
          </b-card-title>
        </b-card-header>
        <b-card-body v-if="row.productList.length > 0">
          <price-card
            :product-type="index"
            :product-group-id="row.id"
            :remove-item="removeItem"
            :locked="editLocked"
          />
        </b-card-body>
        <b-card-body>
          <b-button
            variant="primary"
            size="sm"
            :disabled="editLocked"
            @click="addRow(index)"
          >
            <feather-icon icon="PlusIcon" />
            Yeni Ürün
          </b-button>
        </b-card-body>
        <b-card-footer>
          <b-row v-if="row.productList.length > 0">
            <b-col
              class="text-right"
              cols="9"
            >
              <b-media no-body>
                <b-media-aside
                  class="mr-2"
                />
                <b-media-body
                  v-if="row.productList.length > 0 && row.productList[0].price"
                  class="my-auto"
                >
                  <b-card-text class="font-small-3 mb-0">
                    Teklif Toplam Tutar
                  </b-card-text>
                  <h4

                    class="font-weight-bolder mb-0"
                  >
                    <template v-if="row.productList.length > 0 ">
                      {{ calculateTotal(index,'price') | toCurrency }}<span class="text-primary"> {{ row.productList[0].currency }}</span>
                    </template>
                  </h4>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col class="text-right">
              <b-media no-body>
                <b-media-aside
                  class="mr-2"
                />

                <b-media-body
                  v-if="dataItem.currency ||row.productList.length>0 && row.productList[0].listed_price"
                  class="my-auto"
                >
                  <b-card-text class="font-small-3 mb-0">
                    Teklif Grup Toplam Fiyatı
                  </b-card-text>
                  <h4 class="font-weight-bolder mb-0">
                    {{ calculateTotal(index,'listed_price') | toCurrency }}<span class="text-primary"> {{ dataItem.currency }}</span>
                  </h4>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-card>
    </div>
    <b-row
      v-if="dataList[0].productList.length > 0"
      class="mb-2"
    >
      <b-col>
        <div
          v-if="dataList[0].productList[0].price > 0"
          class="text-left"
        >
          <h3>{{ calculateProductTotal('price') | toCurrency }} <span class="text-primary"> {{ dataList[0].productList[0].currency }}</span></h3>
          <h5>Toplam Proje Ürün Tutarı</h5>
        </div>
      </b-col>
      <b-col v-if="dataItem.discount_amount > 0">
        <div class="text-right text-muted">
          <del>
            <h5>{{ calculateProductTotal('listed_price') | toCurrency }} <span class="text-primary"> {{ dataItem.currency }}</span></h5>
          </del>
          <h3>{{ (calculateProductTotal('listed_price') - dataItem.discount_amount) | toCurrency }} <span class="text-primary"> {{ dataItem.currency }}</span></h3>
          <h5>Toplam Genel Liste İndirimli Ürün Tutarı</h5>
        </div>
      </b-col>
      <b-col v-else>
        <div class="text-right">
          <h3>{{ calculateProductTotal('listed_price') | toCurrency }} <span class="text-primary"> {{ dataItem.currency }}</span></h3>
          <h5>Toplam Genel Liste Ürün Tutarı</h5>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BButton, BCard, BCardBody, BCardFooter,
  BCardHeader, BCardText,
  BCardTitle, BCol, BMedia, BMediaAside, BMediaBody, BRow,

} from 'bootstrap-vue'
import PriceCard from '@/views/Admin/Offers/Components/PricingCard.vue'

export default {
  name: 'PriceListCard',
  components: {
    BMediaAside,
    BMedia,
    BCardText,
    BMediaBody,
    BCol,
    BCardFooter,
    BRow,
    PriceCard,
    BButton,
    BCard,
    BCardBody,
    BCardTitle,
    BCardHeader,
  },
  props: {
    removeItem: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      editLocked: false,
    }
  },

  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
    dataList() {
      return this.$store.getters['offerLines/productList']
    },

  },
  created() {
    this.getTaxes()
    this.getUnits()
    this.getProducts()
    this.lockedInputs()
  },
  methods: {
    lockedInputs() {
      if (this.$route.name === 'OfferEdit') {
        if (this.userData.id_user_types === this.$store.state.app.ProjectMarketingUserTypeId) {
          this.editLocked = true
        }
      }
    },
    getTaxes() {
      this.$store.dispatch('taxes/getDataList')
    },
    getProducts() {
      this.$store.dispatch('products/getDataList')
    },
    getUnits() {
      this.$store.dispatch('units/getDataList')
    },
    addRow(index) {
      this.dataList[index].productList.push({
        id: null,
        notes: null,
        amount: 0,
        price: null,
        listed_price: 0,
        id_products: null,
        id_projects: null,
        id_units: 1,
        units: 'Adet', // 1. id ye karşılık gelen title
        discounted_price: null,
        discount_amount: null,
        discount_rate: null,
        discountStatus: false,
        id_currencies: this.dataItem.id_currencies ? this.dataItem.id_currencies : null,
        id_taxes: '1', // %0 kdv - değişirse değişmeli
      })
    },
    calculateProductTotal(output) {
      const totalPrice = []
      for (let i = 0; i < this.dataList.length; i += 1) {
        for (let l = 0; l < this.dataList[i].productList.length; l += 1) {
          totalPrice.push(this.dataList[i].productList[l])
        }
      }
      const total = totalPrice.reduce((acc, item) => acc + (parseFloat(this.calculatingTotal(output, item))), 0)
      return isNaN(total) ? 0 : total
    },
    calculateTotal(index, output) {
      const priceData = this.dataList[index].productList
      const total = priceData.reduce((acc, item) => acc + (parseFloat(this.calculatingTotal(output, item))), 0)
      return isNaN(total) ? 0 : total
    },
    calculateDiscountTotal(item) {
      if (item.discounted_price > 0) {
        return item.discounted_price
      }
      return item.listed_price
    },
    calculatingTotal(output, item) {
      if (output === 'price') {
        return (item.price * item.amount)
      }
      if (item.discounted_price > 0) {
        return item.discounted_price
      }
      return (item.listed_price * item.amount)
    },
  },
}
</script>
