<template>
  <b-form-group
    label="Karşı Firma İlgili Kişi"
    label-for="id_customer_related_person"
  >
    <validation-provider
      #default="{ errors }"
      name="Karşı Firma İlgili Kişi"
      rules="required"
    >
      <v-select
        id="id_customer_related_person"
        v-model="dataItem.id_customer_related_person"
        label="name"
        placeholder="Seçiniz"
        :reduce="item => item.id"
        :options="relatedPerson"
      >
        <template v-slot:option="option">
          <div>
            {{ option.name }}
          </div>
          <div v-if="option.related_person_types">
            <small class="text-primary">{{ option.related_person_types }}</small>
          </div>
        </template>
        <template v-slot:selected-option="option">
          <div>
            {{ option.name }}
            <span
              v-if="option.related_person_types"
              class="text-primary"
            >{{ option.related_person_types }}</span>
          </div>
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>

    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, localize } from 'vee-validate'

export default {
  name: 'CustomerRelatedPerson',
  components: {
    ValidationProvider,
    BFormGroup,
    vSelect,
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
    relatedPerson() {
      return this.$store.getters['customerRelatedPersons/dataList']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
