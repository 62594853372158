<template>
  <div>
    <div
      v-for="(row,index) in dataList[productType].productList"
      :key="index"
    >
      <b-row>
        <b-col>
          <div class="text-right">
            <b-button
              variant="outline-danger"
              size="sm"
              pill
              class="btn-icon mt-1"
              :disabled="locked"
              @click="removeItem(row.id,productType,index)"
            >
              <FeatherIcon icon="TrashIcon" />
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <!-- sol taraf -->
        <b-col>
          <b-row>
            <b-col>
              <div class="font-weight-light">
                <b-form-group
                  label="Ürün"
                  label-for="id_products"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Ürün"
                    rules="required"
                  >
                    <v-select
                      id="id_products"
                      v-model="row.id_products"
                      :options="productLists"
                      label="title"
                      :reduce="item => item.id"
                      placeholder="Seçiniz"
                      :disabled="locked"
                    >
                      <template v-slot:option="option">
                        <div>
                          {{ option.pcode }} - {{ option.title }}
                        </div>
                        <div v-if="option.product_groups">
                          <small class="text-primary">{{ option.product_groups }}</small>
                        </div>
                      </template>
                      <template v-slot:selected-option="option">
                        <div>
                          {{ option.title }}
                        </div>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-col>
            <b-col>
              <div class="font-weight-light">
                <b-form-group
                  label="Miktar"
                  label-for="amount"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Miktar"
                    rules="required"
                  >
                    <b-input-group>
                      <cleave
                        id="amount"
                        v-model="row.amount"
                        class="form-control"
                        :raw="true"
                        :options="options"
                        placeholder="Giriniz"
                        :disabled="locked"
                      />
                      <template #append>
                        <b-dropdown
                          v-model="row.id_units"
                          text="Dropdown"
                          variant="outline-primary"
                          :disabled="locked"
                        >
                          <template #button-content>
                            {{ !selectedUnitTitle[index] ? row.units ? row.units : 'Seçiniz' : selectedUnitTitle[index] }}
                          </template>
                          <div
                            v-for="(item,indexx) in units"
                            :key="indexx"
                          >
                            <b-dropdown-item @click="selectDropdown(item.id,index)">
                              {{ item.title }}
                            </b-dropdown-item>
                          </div>
                        </b-dropdown>
                      </template>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <!-- sag taraf -->
        <b-col>
          <b-row>
            <b-col>
              <b-form-group
                label="Teklif Birim Fiyatı"
                label-for="listed_price"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Teklif Birim Fiyatı"
                  rules="required"
                >
                  <b-input-group>
                    <cleave
                      id="listed_price"
                      v-model="row.listed_price"
                      class="form-control"
                      :raw="true"
                      :options="options"
                      placeholder="Giriniz"
                      :disabled="locked"
                    />
                    <template
                      v-if="offerData.id_currencies"
                      #append
                    >
                      <b-input-group-text>{{ offerData.currency }}</b-input-group-text>
                    </template>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <div
                    v-if="row.price"
                    class="font-weight-light"
                  >
                    Birim Fiyatı : {{ row.price | toCurrency }}
                    <span class="text-primary">
                      {{ row.currency }}
                    </span>
                  </div>

                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Vergi Oranı"
                label-for="id_taxes"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Vergi Oranı"
                  rules="required"
                >
                  <v-select
                    id="id_taxes"
                    v-model="row.id_taxes"
                    :options="taxes"
                    label="title"
                    :reduce="item => item.id"
                    placeholder="Seçiniz"
                    :disabled="locked"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Not"
            label-for="notes"
          >
            <b-form-textarea
              id="notes"
              v-model="row.content"
              placeholder="Not"
              :disabled="locked"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- BURAYI ACMAK ICIN 20.000 TL ALINACAK  -->
      <!--      <b-row class="d-flex align-items-center">-->
      <!--        <b-col cols="auto">-->
      <!--          <b-form-checkbox-->
      <!--            name="check-button"-->
      <!--            switch-->
      <!--            inline-->
      <!--            :checked="row.discountStatus"-->
      <!--            @change="discountToggle(index)"-->
      <!--          >-->
      <!--            İskonto-->
      <!--          </b-form-checkbox>-->
      <!--        </b-col>-->
      <!--        <b-col v-if="row.discountStatus">-->
      <!--          <discount-amount-->
      <!--            :data-item="productType"-->
      <!--            :item-key="index"-->
      <!--          />-->
      <!--        </b-col>-->
      <!--        <b-col v-if="row.discountStatus">-->
      <!--          <discount-rate-->
      <!--            :data-item="productType"-->
      <!--            :item-key="index"-->
      <!--          />-->
      <!--        </b-col>-->
      <!--      </b-row>-->
      <!--      <b-row class="mt-1">-->
      <!--        <b-col v-if="row.discountStatus">-->
      <!--          <discounted-price-->
      <!--            :data-item="productType"-->
      <!--            :item-key="index"-->
      <!--          />-->
      <!--        </b-col>-->
      <!--        <b-col>-->
      <!--          <total-price-->
      <!--            v-if="row.discountStatus"-->
      <!--            :data-item="productType"-->
      <!--            :item-key="index"-->
      <!--          />-->
      <!--        </b-col>-->
      <!--      </b-row>-->
      <hr>
    </div>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormTextarea, BInputGroup, BDropdown, BDropdownItem, BButton, BFormCheckbox, BInputGroupText,
} from 'bootstrap-vue'
import { ValidationProvider, localize } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import { required } from '@validations'
import vSelect from 'vue-select'
// import DiscountRate from '@/views/Admin/Offers/elements/Products/DiscountRate.vue'
// import DiscountAmount from '@/views/Admin/Offers/elements/Products/DiscountAmount.vue'
// import DiscountedPrice from '@/views/Admin/Offers/elements/Products/DiscountedPrice.vue'
// import TotalPrice from '@/views/Admin/Offers/elements/Products/TotalPrice.vue'

export default {
  name: 'PricingCard',
  components: {
    // TotalPrice,
    // DiscountedPrice,
    // DiscountAmount,
    // DiscountRate,
    BButton,
    vSelect,
    BInputGroup,
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
    BRow,
    BCol,
    Cleave,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BInputGroupText,
  },
  props: {

    removeItem: {
      type: Function,
      required: true,
    },
    productType: {
      type: Number,
      required: true,
    },
    productGroupId: {
      type: String,
      required: true,
    },
    locked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      selectedUnitTitle: [],
      options: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
      },
    }
  },
  computed: {
    offerData() {
      return this.$store.getters['offers/dataItem']
    },
    taxes() {
      return this.$store.getters['taxes/dataList']
    },
    products() {
      return this.$store.getters['products/dataList']
    },
    dataList() {
      return this.$store.getters['offerLines/productList']
    },
    productLists() {
      return this.filterProduct()
    },
    units() {
      return this.$store.getters['units/dataList']
    },
    currencies() {
      return this.$store.getters['currencies/dataList']
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    discountToggle(index) {
      this.dataList[this.productType].productList[index].discountStatus = !this.dataList[this.productType].productList[index].discountStatus
      if (this.dataList[this.productType].productList[index].discountStatus === false) {
        this.dataList[this.productType].productList[index].discount_amount = null
        this.dataList[this.productType].productList[index].discount_rate = null
        this.dataList[this.productType].productList[index].discounted_price = null
      }
    },
    selectDropdown(item, index) {
      const foundRow = this.units.find(unit => unit.id === item)
      this.dataList[this.productType].productList[index].id_units = foundRow.id
      this.selectedUnitTitle[index] = foundRow.title
    },
    // eslint-disable-next-line consistent-return
    filterProduct() {
      return this.products.filter(item => item.id_product_groups === this.productGroupId)
    },
  },

}
</script>
