<template>
  <div>
    <b-card title="Teklif Özeti">
      <b-row>
        <b-col>
          <offer-date />
        </b-col>
        <b-col>
          <valid-date />
        </b-col>
        <b-col>
          <offer-no />
        </b-col>
        <b-col
          cols="12"
        >
          <offer-status :is-new="true" />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <company />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <customer-related-person />
        </b-col>
        <b-col cols="12">
          <offer-title />
        </b-col>
        <b-col cols="12">
          <offer-content />
        </b-col>
        <b-col cols="12">
          <notes />
        </b-col>
        <b-col cols="12">
          <currencies />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="İndirim Tutarı"
            label-for="discount_amount"
          >
            <b-input-group :append="dataItem.currency">
              <cleave
                id="discount_amount"
                v-model="dataItem.discount_amount"
                class="form-control"
                :raw="true"
                :options="options.float"
                placeholder="Giriniz"
                :disabled="dataItem.discount_rate > 0"
                @input="calcDiscount"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="İndirim Oranı"
            label-for="discount_rate"
          >
            <b-input-group append="%">
              <cleave
                id="discount_rate"
                v-model="dataItem.discount_rate"
                class="form-control"
                :raw="true"
                :options="options.float"
                placeholder="Giriniz"
                @input="calcDiscount"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <price-list-card
      v-if="dataList.length > 0"
      :remove-item="removeItem"
    />
    <template v-else>
      <b-card no-body>
        <b-card-body>
          <b-alert
            variant="warning"
            show
          >
            <div class="alert-body text-center">
              <div class="mb-1">
                <!-- Teklif ürünlerini eklemek istediğiniz seçeneği seçin.-->
                Teklif içeriğinde ürün bulunmamakta.
              </div>
              <!--              <b-button-->
              <!--                variant="primary"-->
              <!--                size="sm"-->
              <!--                @click="addItems"-->
              <!--              >-->
              <!--                Ürünleri Proje İçeriğinden Getir-->
              <!--              </b-button>-->
              <b-button
                variant="primary"
                size="sm"
                class="ml-2"
                @click="newProductRow"
              >
                Ürün Ekle
              </b-button>
            </div>
          </b-alert>
        </b-card-body>
      </b-card>
    </template>
    <b-card title="Özel Şartlar">
      <offer-terms />
      <offer-term />
    </b-card>
    <save-button
      :save-action="submitForm"
      :is-disabled="submitStatus"
    />
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BAlert, BButton, BCardBody, BFormGroup, BInputGroup,
} from 'bootstrap-vue'
import OfferDate from '@/views/Admin/Offers/elements/OfferDate.vue'
import ValidDate from '@/views/Admin/Offers/elements/ValidDate.vue'
import OfferNo from '@/views/Admin/Offers/elements/OfferNo.vue'
import OfferStatus from '@/views/Admin/Offers/elements/OfferStatus.vue'
import OfferTitle from '@/views/Admin/Offers/elements/Title.vue'
import OfferContent from '@/views/Admin/Offers/elements/Content.vue'
import Notes from '@/views/Admin/Offers/elements/Notes.vue'
import OfferTerms from '@/views/Admin/Offers/elements/OfferTerms.vue'
import OfferTerm from '@/views/Admin/Offers/elements/OfferTerm.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'
import Currencies from '@/views/Admin/Offers/elements/Currencies.vue'
import PriceListCard from '@/views/Admin/Offers/Components/PriceListCard.vue'
import Company from '@/views/Admin/Offers/elements/Company.vue'
import CustomerRelatedPerson from '@/views/Admin/Offers/elements/CustomerRelatedPerson.vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'OfferForm',
  components: {
    BFormGroup,
    BInputGroup,
    CustomerRelatedPerson,
    Company,
    PriceListCard,
    Currencies,
    BButton,
    BAlert,
    BRow,
    BCol,
    BCard,
    OfferDate,
    ValidDate,
    OfferNo,
    OfferStatus,
    OfferTitle,
    OfferContent,
    Notes,
    OfferTerms,
    OfferTerm,
    SaveButton,
    BCardBody,
    Cleave,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
    removeItem: {
      type: Function,
      required: true,
    },
    addItems: {
      type: Function,
      required: true,
    },
    submitStatus: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      options: {
        float: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['offerLines/productList']
    },
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
    projectData() {
      return this.$store.getters['projects/dataItem']
    },
  },
  methods: {
    newProductRow() {
      this.$store.dispatch('offerLines/getNewProductList')
    },
    calcDiscount() {
      // oran hesapla
      const totalPrice = []
      for (let i = 0; i < this.dataList.length; i += 1) {
        for (let l = 0; l < this.dataList[i].productList.length; l += 1) {
          totalPrice.push(this.dataList[i].productList[l])
        }
      }
      let total = totalPrice.reduce((acc, item) => acc + (parseFloat(item.listed_price * item.amount)), 0)
      if (total < 1) {
        total = this.dataItem.totalPrice
      }

      // orandan tutar hesapla
      if (Number(this.dataItem.discount_rate > 0)) {
        this.dataItem.discount_amount = (this.dataItem.discount_rate * total) / 100
      }
    },
  },
}
</script>
