<template>
  <b-form-group
    label="Teklif Sahibi"
    label-for="id_company"
  >
    <validation-provider
      #default="{ errors }"
      name="Teklif Sahibi"
      rules="required"
    >
      <v-select
        id="id_company"
        v-model="dataItem.id_company"
        label="title"
        placeholder="Seçiniz"
        :reduce="item => item.id"
        :options="companies"
      />
      <small class="text-danger">{{ errors[0] }}</small>

    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, localize } from 'vee-validate'

export default {
  name: 'Company',
  components: {
    ValidationProvider,
    BFormGroup,
    vSelect,
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
    companies() {
      return this.$store.getters['configCompany/dataList']
    },
  },
  created() {
    this.getDataList()
    localize('tr')
  },
  methods: {
    getDataList() {
      this.$store.dispatch('configCompany/getDataList')
    },
  },
}
</script>
