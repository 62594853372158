var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.dataList[_vm.productType].productList),function(row,index){return _c('div',{key:index},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"btn-icon mt-1",attrs:{"variant":"outline-danger","size":"sm","pill":"","disabled":_vm.locked},on:{"click":function($event){return _vm.removeItem(row.id,_vm.productType,index)}}},[_c('FeatherIcon',{attrs:{"icon":"TrashIcon"}})],1)],1)])],1),_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',[_c('div',{staticClass:"font-weight-light"},[_c('b-form-group',{attrs:{"label":"Ürün","label-for":"id_products"}},[_c('validation-provider',{attrs:{"name":"Ürün","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"id_products","options":_vm.productLists,"label":"title","reduce":function (item) { return item.id; },"placeholder":"Seçiniz","disabled":_vm.locked},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',[_vm._v(" "+_vm._s(option.pcode)+" - "+_vm._s(option.title)+" ")]),(option.product_groups)?_c('div',[_c('small',{staticClass:"text-primary"},[_vm._v(_vm._s(option.product_groups))])]):_vm._e()]}},{key:"selected-option",fn:function(option){return [_c('div',[_vm._v(" "+_vm._s(option.title)+" ")])]}}],null,true),model:{value:(row.id_products),callback:function ($$v) {_vm.$set(row, "id_products", $$v)},expression:"row.id_products"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_c('b-col',[_c('div',{staticClass:"font-weight-light"},[_c('b-form-group',{attrs:{"label":"Miktar","label-for":"amount"}},[_c('validation-provider',{attrs:{"name":"Miktar","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-dropdown',{attrs:{"text":"Dropdown","variant":"outline-primary","disabled":_vm.locked},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" "+_vm._s(!_vm.selectedUnitTitle[index] ? row.units ? row.units : 'Seçiniz' : _vm.selectedUnitTitle[index])+" ")]},proxy:true}],null,true),model:{value:(row.id_units),callback:function ($$v) {_vm.$set(row, "id_units", $$v)},expression:"row.id_units"}},_vm._l((_vm.units),function(item,indexx){return _c('div',{key:indexx},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.selectDropdown(item.id,index)}}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),0)]},proxy:true}],null,true)},[_c('cleave',{staticClass:"form-control",attrs:{"id":"amount","raw":true,"options":_vm.options,"placeholder":"Giriniz","disabled":_vm.locked},model:{value:(row.amount),callback:function ($$v) {_vm.$set(row, "amount", $$v)},expression:"row.amount"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Teklif Birim Fiyatı","label-for":"listed_price"}},[_c('validation-provider',{attrs:{"name":"Teklif Birim Fiyatı","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([(_vm.offerData.id_currencies)?{key:"append",fn:function(){return [_c('b-input-group-text',[_vm._v(_vm._s(_vm.offerData.currency))])]},proxy:true}:null],null,true)},[_c('cleave',{staticClass:"form-control",attrs:{"id":"listed_price","raw":true,"options":_vm.options,"placeholder":"Giriniz","disabled":_vm.locked},model:{value:(row.listed_price),callback:function ($$v) {_vm.$set(row, "listed_price", $$v)},expression:"row.listed_price"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(row.price)?_c('div',{staticClass:"font-weight-light"},[_vm._v(" Birim Fiyatı : "+_vm._s(_vm._f("toCurrency")(row.price))+" "),_c('span',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(row.currency)+" ")])]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Vergi Oranı","label-for":"id_taxes"}},[_c('validation-provider',{attrs:{"name":"Vergi Oranı","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"id_taxes","options":_vm.taxes,"label":"title","reduce":function (item) { return item.id; },"placeholder":"Seçiniz","disabled":_vm.locked},model:{value:(row.id_taxes),callback:function ($$v) {_vm.$set(row, "id_taxes", $$v)},expression:"row.id_taxes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Not","label-for":"notes"}},[_c('b-form-textarea',{attrs:{"id":"notes","placeholder":"Not","disabled":_vm.locked},model:{value:(row.content),callback:function ($$v) {_vm.$set(row, "content", $$v)},expression:"row.content"}})],1)],1)],1),_c('hr')],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }