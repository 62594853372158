<template>
  <b-form-group
    label="Para Birimi"
    label-for="id_currencies"
  >
    <validation-provider
      #default="{ errors }"
      name="Para Birimi"
      rules="required"
    >
      <v-select
        id="id_currencies"
        v-model="dataItem.id_currencies"
        label="title"
        placeholder="Seçiniz"
        :reduce="item => item.id"
        :options="currencies"
        @input="selectCurrencyDropdown(dataItem.id_currencies)"
      />
      <small class="text-danger">{{ errors[0] }}</small>

    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, localize } from 'vee-validate'

export default {
  name: 'Currencies',
  components: {
    ValidationProvider,
    BFormGroup,
    vSelect,
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
    currencies() {
      return this.$store.getters['currencies/dataList']
    },
  },
  created() {
    this.getDataList()
    localize('tr')
  },
  methods: {
    getDataList() {
      this.$store.dispatch('currencies/getDataList')
    },
    selectCurrencyDropdown(item) {
      const foundRow = this.currencies.find(e => e.id === item)
      this.dataItem.id_currencies = foundRow.id
      this.dataItem.currency = foundRow.symbol
    },
  },
}
</script>
