<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Teklif Bilgileri</span>
        </template>
        <validation-observer ref="simpleRules">
          <offer-form
            :submit-form="submitForm"
            :submit-status="submitStatus"
            :remove-item="removeItem"
            :add-items="getProductList"
          />
        </validation-observer>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Bilgileri</span>
        </template>
        <customer-info />
        <customer-invoice />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer-history :customer-id="this.projectData.id_customers" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import OfferForm from '@/views/Admin/Offers/OfferForm.vue'
import CustomerInfo from '@/views/Admin/Customers/View/CustomerInfo.vue'
import CustomerInvoice from '@/views/Admin/Customers/View/CustomerInvoice.vue'
import CustomerHistory from '@/views/Admin/Customers/View/CustomerHistory.vue'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'Add',
  components: {
    BTabs,
    BTab,
    OfferForm,
    CustomerInfo,
    CustomerInvoice,
    CustomerHistory,
    ValidationObserver,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
    projectData() {
      return this.$store.getters['projects/dataItem']
    },
    pricingData() {
      return this.$store.getters['projectLines/dataList']
    },
    projectLines() {
      return this.$store.getters['projectLines/dataList']
    },
    dataList() {
      return this.$store.getters['offerLines/productList']
    },
    saveData() {
      return this.$store.getters['offers/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.$router.push(`/app/offers/view/${val.id}`)
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.$store.commit('offers/RESET_DATA_ITEM')
    this.$store.commit('offerLines/RESET_PRODUCT_DATA')
    this.getProjectData()
    localize('tr')
  },
  methods: {
    getCustomer(idCustomers) {
      this.$store.dispatch('customers/getDataItem', idCustomers)
    },
    getCustomerRelatedPerson(index) {
      this.$store.dispatch('customerRelatedPersons/getDataList', {
        where: {
          'customer_related_persons.id_customers': index,
        },
      })
    },

    getProjectData() {
      this.$store.dispatch('projects/getDataItem', this.$route.params.id_projects)
        .then(response => {
          if (response) {
            this.dataItem.id_customers = response.id_customers
            this.getCustomer(response.id_customers)
            this.getCurrency(response.id_currencies)
            this.getCustomerRelatedPerson(response.id_customers)
            this.setOfferContent(response.id_customers)
          }
        })
    },
    setOfferContent(customer) {
      this.$store.dispatch('offers/setContent', customer)
        .then(response => {
          if (response) {
            this.dataItem.content = response
          }
        })
    },
    getCurrency(index) {
      this.$store.dispatch('currencies/getDataItem', index)
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // this.submitStatus = true
          let processError = false
          if (this.dataList.length < 1) {
            this.showToast({
              variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: 'Lütfen "Birim" ve "Para Birimi" alanlarını doldurduğunuzdan emin olun.',
            })
          } else {
            this.dataItem.offer_lines = []
            for (let i = 0; i < this.dataList.length; i += 1) {
              for (let l = 0; l < this.dataList[i].productList.length; l += 1) {
                if (!this.dataItem.id_currencies || !this.dataList[i].productList[l].id_units) {
                  this.showToast({
                    variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: 'Boş teklif kaydedemezsiniz.',
                  })
                  processError = true
                  break
                } else {
                  delete this.dataList[i].productList[l].id
                }
                this.dataItem.offer_lines.push(this.dataList[i].productList[l])
              }
            }
            this.dataItem.id_projects = this.$route.params.id_projects
            if (!processError) {
              this.dataItem.id_offer_statuses = 4 // oto onay ( revize den dolayı )
              this.$store.dispatch('offers/saveData', this.dataItem)
            }
          }
        }
      })
    },
    removeItem(itemId, productType, index) {
      if (this.dataList.length > 0) {
        this.dataList[productType].productList.splice(index, 1)
      }
    },
    getProductList() {
      this.$store.dispatch('offerLines/getProductList', {
        id_projects: this.$route.params.id_projects,
        id_offers: this.dataItem.id,
        addOffer: true,
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
